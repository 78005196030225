/*
############     _settings-index.scss      ############
variables and settings
*/

:root .utah-design-system {
    --primary-color:#314a5f;
    --primary-color-dark:#687781;
    --primary-color-light:#d2d8dc;
    --gray-on-primary-color:#fff;
    --secondary-color:#2e7b36;
    --secondary-color-dark:#3ea448;
    --secondary-color-light:#b4d444;
    --tertiary-color:#ba0000;
    --gray-on-secondary-color:#fff;
    --accent-color:#fca020;
    --gray-on-accent-color:#151515;
    --gray-color:#474747;
    --gray-dark-color:#151515;
    --hover-gray-color:rgba(0,0,0,0.07);
    --hover-gray-color-opaque:rgba(233,233,233);
    --header-primary-color:var(--primary-color);
    --link-color:var(--primary-color);
    --link-color-dark:var(--gray-dark-color);

    --main-width: 700px;
    --main-width-wide: 900px;
    --main-left-width: 200px;
    --main-right-width: 200px;
    --main-padding: var(--spacing-2xl) var(--spacing-xl);
    --main-padding-small: var(--spacing-xl) var(--spacing);
}

.logo-text {
    display: inline-block;
    margin-bottom: 0;
    font-size: 3em;
    font-weight: bold;
    line-height: .8;
    color: #fff;
    text-align: left;
    letter-spacing: .2em;
}

.logo-text > span {
    display: block;
    position: relative;
    top: -88px;
    left: 40px ;
    font-size: .5em;
    font-weight: normal;
    letter-spacing: normal;
}

.utah-design-system .footer-agency-information__title-image > img {
    width: 20%;
}
