@use "@utahdts/utah-design-system/css/index.scss" as media-size-vars;


.app-navigation {
    display: flex;
    width: auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px; // Add padding as needed

    // Add additional styling as needed

        &__nav-left {
            gap: 10px; // Adjust the gap between buttons as needed
            width: 50%; // Occupy half of the container width
            justify-content: center;

            &-lbutton {
                width: auto;
            }

        &__nav-right {
            gap: 10px; // Adjust the gap between buttons as needed
            width: 50%; // Occupy half of the container width

            &__rbutton {
                width: auto;
                justify-content: center;
            }
        }

    }
}
