@use "@utahdts/utah-design-system/css/index.scss" as media-size-vars;

.card-section {
  background-size: cover;
  height: auto;
  width: 100%;
  display: grid;
  justify-content: center;
  margin: var(--spacing-4xl) 0 var(--spacing-5xl);
  padding: 0 var(--spacing-2xl);
  box-sizing: border-box;

  &__card-grid {
    max-width: var(--content-width-medium);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing-l);
    margin: calc(var(--spacing-2xl) * -1) 0;
  }
  &__card {
    background: white;
    padding: var(--spacing);
    border-radius: var(--radius-small);
    box-shadow: var(--elevation-medium-borderless);

    &-title {
      display: flex;
      align-items: center;
      .icon-svg {
        margin-right: var(--spacing-s);
        height: var(--font-size-2xl);
        fill: var(--secondary-color);
      }
      .icon-collaboration {
        height: var(--font-size-xl);
      }
    }

    &-count {
      font-size: xxx-large;
      font-weight: bold;
      text-align: center;
      padding: 25px;
    }

    &-footer {
      font-weight: 600;
      text-align: center;
      padding: 25px;
    }

    p {
      margin: 0;
    }
  }
  &__card-wide {
    grid-column: auto / span 2;
  }
  &__card-narrow {
    grid-column: auto / span 1;
  }

  &__action-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing);

    .action-card {
      grid-column-start: span 2;
      &--center {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }
  }
  &__gray-section {
    background: var(--gray-light-color);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &--accordion-small button.accordion__header * {
    font-size: 1.1rem;
  }

  &__contact-section {
    padding: var(--spacing-5xl) 0;
    text-align: center;
    .icon-chat-bubbles {
      fill: var(--primary-color);
      width: 80px;
    }
  }
}

@media screen and (max-width: #{media-size-vars.$media-size-mobile}) {
  //.home-banner {
  //  background-position: 0 0;
  //  &__title {
  //    font-size: var(--font-size-5xl);
  //    width: min-content;
  //    margin: var(--spacing-xl);
  //    line-height: 1.1;
  //  }
  //}
  .card-section {
    &__card-grid {
      gap: var(--spacing);
    }
    &__card-wide {
      grid-column: auto / span 10;
    }
    &__card-narrow {
      grid-column: auto / span 10;
    }

    &__action-cards {
      .action-card {
        grid-column-start: span 4;
        &--center {
          grid-column-start: 1;
          grid-column-end: 5;
        }
      }
    }
  }
}
