@use "@utahdts/utah-design-system/css/index.scss" as media-size-vars;

.application {
    display: grid;
    justify-items: center;

    &-title {
    padding: 20px 10px;
        justify-items: left;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;

        &-right {
            margin-left: 10px;
        }

    }

    &__button-center {
        display: flex;
        justify-content: center;
    }

    &__vertical {
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        margin-bottom: 10px;


        &-div {
            display: inline-block;
            margin-bottom: 10px;
        }
        &-bottom-right {
            display: flex;
            justify-content: flex-end;
        }
        &-bottom-left {
            display: flex;
            justify-content: flex-start;
        }
        &-max60 {
            max-width: 60%;
        }
        &-max50 {
            text-align: left;
            max-width: 50%;
        }
        &-max35 {
            text-align: left;
            max-width: 35%;
        }
    }
    &-review-info {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 10px;
    }

    &-label {
        font-weight: bolder;
        margin-right: 8px;
        font-size: 25px;
    }

    &-value {
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.50);
    }

    &-warning {
        color: #c40000;
    }

    &-checkbox {
        justify-content: left;
    }

    &__group31 {
        grid-auto-rows: minmax(85px, auto);
        gap: 12px;
        display: inline-grid;
        grid-template-columns: 3fr 1fr;
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        margin-bottom: 10px;
    }

    &__group12 {
        grid-auto-rows: minmax(85px, auto);
        gap: 12px;
        display: inline-grid;
        grid-template-columns: 1fr 2fr;
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        margin-bottom: 10px;
    }

    &__group2 {
        grid-auto-rows: minmax(85px, auto);
        gap: 12px;
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        margin-bottom: 10px;

            &-bottom-left {
              grid-column: 1/1;
            }
            &-bottom-right {
              grid-column: 2/2;
            }
    }

    &__group21-no-border {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    &__group2-no-border {
        gap: 12px;
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &__coapp-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-bottom-left {
            grid-column: 1/1;
        }
        &-bottom-right {
            grid-column: 2/2;
            float: right;
        }
    }

    &__group {
        grid-auto-rows: minmax(85px, auto);
        gap: 12px;
        display: inline-grid;
        grid-template-columns: repeat(3, 1fr);
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        //margin-bottom: 10px;

        &-bottom-left {
            grid-column: 1/1;
        }
        &-bottom-right {
            grid-column: 3/3;
            justify-self: right;
            align-self: end;
        }
    }
    &__legal-names {
        display: flex;
        justify-content: space-between;

        &-content {
            flex-grow: 1;
        }

        &-button {
            margin-bottom: 10px;
            margin-left: 10px;
            margin-top: 25px;
        }
    }

    &__review {
        display: grid;
        grid-template-columns: 1fr;
        background: white;
        padding: var(--spacing);
        border-radius: var(--radius-small);
        box-shadow: var(--elevation-medium-borderless);
        margin-bottom: 10px;

        &-group {
            gap: 12px;
            display: inline-grid;
            grid-template-columns: repeat(3, 1fr);
            background: white;
            padding: var(--spacing);
            border-radius: var(--radius-small);
            box-shadow: var(--elevation-medium-borderless);
            width: 97%;
        }
    }

    @media only screen and (max-width: 600px) {
        /* Adjustments for smaller screens */

        &__vertical {
            max-width: 100%; /* Make it full width */
        }

        &-review-info {
            grid-template-columns: 1fr; /* Single column for review info on smaller screens */
        }

        &__group31,
        &__group12,
        &__group2,
        &__group {
            grid-template-columns: 1fr; /* Single column for groups on smaller screens */
        }

        &__coapp-buttons {
            grid-template-columns: 1fr; /* Single column for coapp buttons on smaller screens */

            &-bottom-left,
            &-bottom-right {
                grid-column: auto; /* Reset grid-column for coapp buttons on smaller screens */
            }
        }

        &__review {
            &-group {
                grid-template-columns: 1fr; /* Single column for review group on smaller screens */
            }
        }
    }
}


