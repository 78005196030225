.page-not-found {
  &__content {
    min-height: 30vh;
    display: flex;
    align-items: center;
  }
  .top-banner {
    min-height: 25vh;
    height: 200px;
    width: 100%;
    background-size: cover;

  }
}
