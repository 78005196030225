
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.custom-button {
    background-color: white !important;
    color: red !important;
    border-color: red !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
    transition: background-color 0.3s, color 0.3s;
}

.custom-button:hover {
    background-color: red !important;
    color: white !important;
}

.sah-app-button-green {
    border-color: #78c146 !important;
    background-color: white !important;
}

.sah-app-button-green:hover {
    background-color: #78c146 !important;
    color: white !important;
}

.last-row td {
    border-bottom: none !important;
}

.error-message {
    color: red !important;
    font-size: 14px !important;
    padding: 5px !important;
    /* Add any other styling properties you need */
}

.error-input {
    border: 1px solid red !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;

    }
}

@media ( max-width: 768px) {
    .float-right-sm {
        float: right;
    }
}

@media ( min-width: 768px) {
    .float-right-lg {
        float: right;
    }
}

@media ( max-width: 768px) {
    .float-left-sm {
        float: left;
    }
}

@media ( min-width: 768px) {
    .float-left-lg {
        float: left;
    }
}

.App-header {
    background-color: #2765e4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #2765e4;

}

.App-link {
    color: #2765e4;
}

h2 {
    border-radius: 25px;
    background-color: lightgray;

    max-width: 100%;

}

.right {
    position: absolute;
    right: 0px;
    padding: 10px;
}

table {
    display: inline-table;
    min-width: 25%;

}

.tr-center {
    align-content: center !important;

}

.td-left {
    align-items: end;
    text-align: left;
    align-content: flex-start;
    min-width: 400px;
    max-width: 450px;
}

.p {
    max-width: 500px;
    min-width: 50px;
}

.td-right {
    align-content: flex-end;
    text-align: right;
    padding: 10px;
    max-width: 150px;
    min-width: 50px;
}

li {
    display: -ms-inline-flexbox;
    list-style-type: none;
}

input[type=text] {
    min-width: fit-content;
    display: -ms-inline-flexbox;
}

input[type=checkbox] {
    margin-left: 300px;
}

input[type=date] {
    width: 60%;
    max-width: 40%;
    min-height: 30px;
    min-width: 25%;
    padding-left: 5px;
    border-radius: 5px;
    border-width: thin;
}

input[type=number] {

    max-width: 40%;
    min-height: 35px;
    min-width: 30%;
    border-radius: 5px;
    border-width: thin;

}

input[type=email] {
    border-radius: 5px;
    min-height: 35px;
    min-width: 98%;
    border-width: thin;
}

input[type=tel] {
    max-width: 40%;
    max-height: 140%;
    min-height: 35px;
    min-width: 30%;
    border-radius: 5px;
    border-width: thin;
}

.PatternFormat{
    max-width: 100%;
    max-height: 140%;
    min-height: 35px;
    min-width: 30%;
    border-radius: 5px;
    border-width: thin;
}

ol {
    border: 1px solid;
    line-height: 180%;
    list-style-position: inside;


}

ol li {
    list-style-type: none;
    padding: 16px 16px;
    border-bottom: 1px solid;
    border-left: 1px solid;
}
.pending-li{
    border: 1px solid !important;
    border-radius: 15px;
    padding: 10px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 10px;
    min-height: 35px;
    background-color: lightgray;
}

.ul-pending{

    border-radius: 5px;
}

.delete-coApplication-button{
  float: right;
margin-bottom: 10px !important;
}

.coap-edit-btn{
    float: right;
    margin-right: 15px !important;

}

.coapp-add-button{
  float: left;
    margin-left: 60px !important;
    margin-right: 15px!important;
    margin-bottom: 140px;
}
clear-coapp-button{
    float: left;
margin-left: 10px !important;
}

.button {
    border-radius: 25px;
    color: white;
    background-color: lightblue ;

}

.button-center {
    text-align: center;
    color: white;
    background-color: lightblue;
    border-radius: 25px;

}

.button-center:hover {
    color: white;
    background-color: darkblue;
}

.btn-next-to {
    border-radius: 25px;
    text-align: center;
    margin-left: 1000px;

}

.button-right {

    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 400px;
    min-width: 150px;
    float: right;
}

.button-left {
    margin-top: 40px;
    margin-bottom: 50px;
    min-width: 150px;
    float: left;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }


}


