@use "@utahdts/utah-design-system/css/index.scss" as class-vars;

#{class-vars.$base-class} {
  .spinner {
    &.fullscreen {
      position: absolute;
      background: rgba(0, 0, 0, 0.25);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;

      .spinner__track {
        stroke: white;
      }
    }
  }
}
