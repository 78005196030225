/*
############     _generic-index.scss      ############
css resets, etc.
*/


/**
 * Remove the margin in all browsers.
 */

body {
  margin: 0;
}
